export default () => [
  // 2x Nectar Points
  {
    from: new Date('08 October 2024 00:00:00 UTC'),
    content: {
      text: 'Earn 2x Nectar points shopping with Tu online! For more details',
      link: '/help/nectar?tag=tu:propbar',
      colour: 'purple',
      icon: 'nectar',
    },
  },
  // base propbar
  {
    from: new Date('15 October 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
]
